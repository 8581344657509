import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Modal from '../../components/reusable/Modals/Modal';
import Popup from '../../components/reusable/Popups/Popup';
import api from '../../config/api';
import apiv2 from '../../config/apiv2';
import { SETTING_USAGE_BILLING_PATH } from '../../contants';
import {
  setCardData,
  setIsDelete,
  setIsPayment,
} from '../../features/userSettings/userSettingsSlice';
import Invoices from './Invoices';
import Payment from './Payment';
import Usage from './Usage';
import css from './UserSettings.module.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const UserSettings = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [invoices, setInvoices] = useState([]);
  const [tooltip, setTooltip] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch();

  const { isDelete, isPayment } = useSelector(state => state.userSettings);
  const { notifications } = useSelector(state => state.me);

  const isTrialPeriod = !notifications?.trial_period_expired;

  useEffect(() => {
    handleCancel();
    if(location.pathname === SETTING_USAGE_BILLING_PATH) {
      getInvoices()
      apiv2
        .COMPANIES_BILLING_GET()
        .then(res => dispatch(setCardData(res.data)));
    }
  }, [location.pathname]);

  const getInvoices = async () => {
    try {
      setIsLoading(true);
      const res = await apiv2.INVOICES();
      setInvoices(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    dispatch(setIsDelete(false));
    dispatch(setIsPayment(false));
  };

  const handleDelete = () => {
    location.pathname.split('/')[2] === 'account'
      ? api.DELETE_COMPANY().then(() => {
          localStorage.removeItem('ACCESS_TOKEN');
          localStorage.removeItem('REFRESH_TOKEN');
          window.location.replace('/login');
        })
      : apiv2.DELETE_ME().then(() => {
          localStorage.removeItem('ACCESS_TOKEN');
          localStorage.removeItem('REFRESH_TOKEN');
          window.location.replace('/login');
        });
    handleCancel();
  };

  return (
    <div className={css.main}>
      {isDelete && (
        <Popup
          type={location.pathname.split('/')[2]}
          onClose={handleCancel}
          onClick={handleDelete}
        />
      )}
      {isPayment && (
        <Popup
          type="addPayment"
          onClose={handleCancel}
          setInvoices={setInvoices}
        />
      )}
      {location.pathname.split('/')[2] !== 'usage-billing' ? (
        <>
          <Modal type={location.pathname.split('/')[2] + 1} />
          <Modal type={location.pathname.split('/')[2] + 2} />
          {location.pathname.split('/')[2] === 'user' && (
            <Modal type={location.pathname.split('/')[2] + 3} />
          )}
        </>
      ) : (
        <div className={css.wrapper}>
          <div className={css.df}>
            <Usage
              isTrialPeriod={isTrialPeriod}
              setTooltip={setTooltip}
              tooltip={tooltip}
            />
            <Elements stripe={stripePromise}>
              <Payment setInvoices={setInvoices} />
            </Elements>
          </div>
          <Invoices invoices={invoices} isLoading={isLoading} />
        </div>
      )}
    </div>
  );
};

export default UserSettings;
