import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import SelectCampaigns from '../../components/SelectCampaigns/SelectCampaigns';
import SelectClient from '../../components/SelectClient/SelectClient';
import SetBudget from '../../components/SetBudget/SetBudget';
import Popup from '../../components/reusable/Popups/Popup';
import api from '../../config/api';
import apiv2 from '../../config/apiv2';
import { DASHBOARD_PATH } from '../../contants';
import {
  changeIsConfirm,
  changeSetCampaignTab,
  changeStep,
  resetState,
  setIsActive,
} from '../../features/createBudget/createBudgetSlice';
import { setViewOptions } from '../../features/dashboard/dashboardSlice';
import { setIsNewDataSource } from '../../features/dataSource/dataSourceSlice';
import { setMe } from '../../features/me/meSlice';
import { getMinuteDiffWithNow, getViewOptions } from '../../helpers';
import {
  failCreateBudget,
  failUpdateBudget,
  successCreateBudget,
  successUpdateBudget,
} from '../../helpers/notyf';
import css from './CreateBudget.module.css';

const CreateBudget = () => {
  const {
    formData,
    tab,
    isActive,
    step,
    choosenCampaigns,
    isConfirm,
  } = useSelector(state => state.createBudget);
  const { isNewDataSource } = useSelector(state => state.dataSource);
  const { viewOptions } = useSelector(state => state.dashboard);

  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, []);

  useEffect(() => {
    if (params.id && !isNaN(params.id)) {
      if (params.step === 'campaigns') {
        dispatch(changeStep('campaigns'));
        dispatch(setIsActive({ step1: false, step2: false, step3: true }));

        // set default campaigns tab
        dispatch(changeSetCampaignTab('campaigns'));
      } else {
        dispatch(setIsActive({ ...isActive, step2: true }));
      }
    }
    dispatch(changeStep(location.pathname.split('/')[2]));
  }, [location, params.id]);

  useEffect(() => {
    const { step3 } = isActive;
    if (step3) {
      api.GET_GOOGLE_DATA_SOURCES().then(res => {
        if (res.length) {
          res.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

          const timeCreatedOfLatestDataSource = new Date(
            res[0].created_at,
          ).getTime();
          const minuteDiff = getMinuteDiffWithNow(
            timeCreatedOfLatestDataSource,
          );

          if (minuteDiff < 20) {
            dispatch(setIsNewDataSource(true));
          } else {
            dispatch(setIsNewDataSource(false));
          }
        }
      });
    }
  }, [isActive]);

  const handleClosePopup = () => dispatch(changeIsConfirm(false));

  const handleConfirm = async () => {
    const isModeEditBudget = params.id && !isNaN(params.id);
    try {
      const dataToSend = {
        client_id: formData.client.value,
        amount: +formData.amount,
        name: formData.name,
        type: tab.charAt(0).toUpperCase() + tab.slice(1),
        campaigns_id: choosenCampaigns.map(elem => elem.id),
        renew_type: formData.renew_type,
      };

      if (tab === 'monthly') {
        dataToSend.month_start_day = formData.month_start_day.value;
      }

      if (tab === 'weekly') {
        dataToSend.week_start_day = formData.week_start_day.value;
      }

      if (tab === 'custom') {
        dataToSend.start_date =
          typeof formData.start_date === 'string'
            ? formData.start_date
            : `${formData.start_date.getFullYear()}-${
                formData.start_date.getMonth() + 1
              }-${formData.start_date.getDate()}`;
        dataToSend.end_date =
          typeof formData.end_date === 'string'
            ? formData.end_date
            : `${formData.end_date.getFullYear()}-${
                formData.end_date.getMonth() + 1
              }-${formData.end_date.getDate()}`;
        dataToSend.renew_type = formData.type.label;
      }

      if (isModeEditBudget) {
        await api.UPDATE_BUDGET(dataToSend, params.id);
        successUpdateBudget();
      } else {
        const res = await api.CREATE_BUDGET({
          ...dataToSend,
          renew_type: dataToSend.renew_type || 'Recurring',
        });
        const { data } = res;

        if (data) {
          const rawNewBudget = JSON.stringify({
            id: data.id,
            syncStatus: false,
          });
          localStorage.setItem('newBudget', rawNewBudget);
        }

        successCreateBudget();
        apiv2.GET_ME().then(res => dispatch(setMe(res.data)));
      }

      await updateStateOnRedux()
      navigate(DASHBOARD_PATH, { replace: true });
    } catch (error) {
      if (isModeEditBudget) {
        failUpdateBudget();
      } else {
        failCreateBudget();
      }
    }
  };

  const updateStateOnRedux = async () => {
    const newViewOptions = await getViewOptions(viewOptions);
    dispatch(setViewOptions(newViewOptions));

    dispatch(changeIsConfirm(false));
    dispatch(resetState());
  };

  const getCurrentStep = isActive => {
    const { step2, step3 } = isActive;

    return step3 ? 3 : step2 ? 2 : 1;
  };

  return (
    <div className={css.main}>
      {isConfirm ? (
        <Popup
          type="budget"
          onClose={handleClosePopup}
          onClick={handleConfirm}
        />
      ) : (
        <>
          <nav className={css.nav}>
            <Link
              className={`${css.step} ${isActive.step1 ? '' : css.disabled}`}
              to={`/create-budget/client${
                params.id && !isNaN(params.id) ? `/${params.id}` : ''
              }`}
            >
              <div
                className={`${css.round} ${isActive.step1 ? css.activeB : ''}`}
              >
                <span
                  className={`${css.stepNumber} ${
                    isActive.step1 ? css.active : ''
                  }`}
                >
                  01
                </span>
              </div>
              <h2
                className={`${css.stepText} ${
                  isActive.step1 ? css.active : ''
                }`}
              >
                Select a Client
              </h2>
            </Link>
            <Link
              className={`${css.step} ${isActive.step2 ? '' : css.disabled}`}
              to={`/create-budget/budget${
                params.id && !isNaN(params.id) ? `/${params.id}` : ''
              }`}
            >
              <div
                className={`${css.round} ${isActive.step2 ? css.activeB : ''}`}
              >
                <span
                  className={`${css.stepNumber} ${
                    isActive.step2 ? css.active : ''
                  }`}
                >
                  02
                </span>
              </div>
              <h2
                className={`${css.stepText} ${
                  isActive.step2 ? css.active : ''
                }`}
              >
                Set Budget
              </h2>
            </Link>
            <Link
              className={`${css.step} ${css.disabled}`}
              to={`/create-budget/campaigns${
                params.id && !isNaN(params.id) ? `/${params.id}` : ''
              }`}
            >
              <div
                className={`${css.round} ${
                  step === 'campaigns' && isActive.step3 ? css.activeB : ''
                }`}
              >
                <span
                  className={`${css.stepNumber} ${
                    step === 'campaigns' && isActive.step3 ? css.active : ''
                  }`}
                >
                  03
                </span>
              </div>
              <h2
                className={`${css.stepText} ${
                  step === 'campaigns' && isActive.step3 ? css.active : ''
                }`}
              >
                Select Campaigns
              </h2>
            </Link>
            <ProgressBar step={getCurrentStep(isActive)} />
          </nav>
          {step === 'client' && <SelectClient />}
          {step === 'budget' && isActive.step2 && <SetBudget />}
          {step === 'campaigns' && isActive.step3 && (
            <SelectCampaigns
              handleConfirm={handleConfirm}
              isNewDataSource={isNewDataSource}
              isAddOrRemoveCampaigns
            />
          )}
          {((step === 'budget' && !isActive.step2) ||
            (step === 'campaigns' && !isActive.step3)) && (
            <h2 className={css.title}>Complete previous step</h2>
          )}
        </>
      )}
    </div>
  );
};

export default CreateBudget;
