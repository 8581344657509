import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import api from '../../config/api';
import { CREATE_BUDGET_PATH, CREATE_CLIENT_PATH } from '../../contants';
import {
  addClient,
  setClients,
  setIsActive,
} from '../../features/createBudget/createBudgetSlice';
import Button from '../reusable/Button/Button';
import Select from '../reusable/Select/Select';
import css from './SelectClient.module.css';

const SelectClient = () => {
  const { isActive, formData } = useSelector(
    state => state.createBudget,
  );
  const { client } = formData;

  const [options, setOptions] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    api.GET_ALL_CLIENTS().then(res => {
      if (res) {
        const dataToSet = res.map(client => ({
          value: client.id,
          label: client.name,
        }));
        setOptions(dataToSet);
        dispatch(setClients(dataToSet));
      }
    });
  }, []);

  const handleCreateClient = () => navigate(CREATE_CLIENT_PATH);

  const handleChange = data => {
    dispatch(addClient(data));
    dispatch(setIsActive({ ...isActive, step2: true }));
    navigate(`${CREATE_BUDGET_PATH}/budget`);
  };

  return (
    <div className={css.wrapper}>
      <div className={css.header}>
        <h1 className={css.title}>Select a Client</h1>
        <Button
          styled="addClient"
          title="Create New Client"
          onClick={handleCreateClient}
        />
      </div>
      <p className={css.text}>
        Clients can have multiple budgets across multiple channels. Select an
        existing client below or create a new client.
      </p>
      <h2 className={css.select}>Client</h2>
      <Select
        options={options}
        value={client || ''}
        placeHolder="Select Client"
        onChange={handleChange}
      />
    </div>
  );
};

export default SelectClient;
