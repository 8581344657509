export const MAX_RETRIES_OF_SYNC_DATA_SOURCE = 15;
export const BACKOFF_FACTOR_OF_SYNC_DATA_SOURCE = 5;

export const MAX_RETRIES_OF_SYNC_ACCOUNT = 5;
export const BACKOFF_FACTOR_OF_SYNC_ACCOUNT= 2;

export const SETTING_USAGE_BILLING_PATH = '/settings/usage-billing';
export const SETTING_ACCOUNT_PATH = '/settings/account';
export const SETTING_USER_PATH = '/settings/user';
export const SETTING_AUTOMATED_RULES_PATH = '/settings/automated-rules';
export const DASHBOARD_PATH = '/dashboard';
export const CREATE_CLIENT_PATH = '/create-client';
export const CREATE_BUDGET_PATH = '/create-budget';
export const CREATE_BUDGET_AT_CLIENT_STEP_PATH = '/create-budget/client';
export const CREATE_TEAM_PATH = '/team';
export const CREATE_ACCOUNT_PATH = '/create-account';
export const CREATE_DATA_SOURCE_PATH = '/data-sources';

export const PROTECTED_ROUTES = [
  DASHBOARD_PATH,
  CREATE_CLIENT_PATH,
  CREATE_BUDGET_PATH,
  CREATE_TEAM_PATH,
  CREATE_DATA_SOURCE_PATH,
  SETTING_AUTOMATED_RULES_PATH,
  SETTING_USER_PATH,
  SETTING_ACCOUNT_PATH,
  SETTING_USAGE_BILLING_PATH,
];
