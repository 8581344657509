import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { CREATE_ACCOUNT_PATH, CREATE_BUDGET_PATH, CREATE_CLIENT_PATH, CREATE_DATA_SOURCE_PATH, CREATE_TEAM_PATH, DASHBOARD_PATH, SETTING_ACCOUNT_PATH, SETTING_AUTOMATED_RULES_PATH, SETTING_USAGE_BILLING_PATH, SETTING_USER_PATH } from '../contants';
import LoginPage from '../pages/LoginPage/LoginPage';
import { PERMISSIONS } from './../contants/permissions';
import CreateAccount from './../pages/CreateAccount/CreateAccount';
import CreateBudget from './../pages/CreateBudget/CreateBudget';
import CreateClient from './../pages/CreateClient/CreateClient';
import Dashboard from './../pages/Dashboard/Dashboard';
import DataSources from './../pages/DataSources/DataSources';
import ConfirmInvitationPage from './../pages/LoginPage/СonfirmInvitationPage';
import ResetPassword from './../pages/ResetPassword/ResetPassword';
import SignupPageV2 from './../pages/SignupPageV2';
import Team from './../pages/Team/Team';
import AutomatedRules from './../pages/UserSettings/AutomatedRules';
import UserSettings from './../pages/UserSettings/UserSettings';
import { ProtectedRoute } from './protectedRoute';

export const ROUTES = [
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/signup',
    element: <SignupPageV2 />,
  },
  {
    path: CREATE_ACCOUNT_PATH,
    element: <CreateAccount />,
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
  },
  {
    path: '/confirm-invitation/:token',
    element: <ConfirmInvitationPage />,
  },
  {
    path: DASHBOARD_PATH,
    allowedPermissions: [
      PERMISSIONS.ADMIN,
      PERMISSIONS.STANDARD,
      PERMISSIONS.READ_ONLY,
    ],
    element: <Dashboard />,
    needValidAccess: true,
  },
  {
    path: CREATE_CLIENT_PATH,
    allowedPermissions: [PERMISSIONS.ADMIN, PERMISSIONS.STANDARD],
    element: <CreateClient />,
    needValidAccess: true,
  },
  {
    path: `${CREATE_CLIENT_PATH}/:id`,
    allowedPermissions: [PERMISSIONS.ADMIN, PERMISSIONS.STANDARD],
    element: <CreateClient />,
    needValidAccess: true,
  },
  {
    path: `${CREATE_BUDGET_PATH}/:step`,
    allowedPermissions: [PERMISSIONS.ADMIN, PERMISSIONS.STANDARD],
    element: <CreateBudget />,
    needValidAccess: true,
  },
  {
    path: `${CREATE_BUDGET_PATH}/:step/:id`,
    allowedPermissions: [PERMISSIONS.ADMIN, PERMISSIONS.STANDARD],
    element: <CreateBudget />,
    needValidAccess: true,
  },
  {
    path: CREATE_TEAM_PATH,
    allowedPermissions: [PERMISSIONS.ADMIN, PERMISSIONS.STANDARD],
    element: <Team />,
    needValidAccess: true,
  },
  {
    path: CREATE_DATA_SOURCE_PATH,
    allowedPermissions: [PERMISSIONS.ADMIN, PERMISSIONS.STANDARD],
    element: <DataSources />,
    needValidAccess: true,
  },
  {
    path: `${CREATE_DATA_SOURCE_PATH}/:source`,
    allowedPermissions: [PERMISSIONS.ADMIN, PERMISSIONS.STANDARD],
    element: <DataSources />,
    needValidAccess: true,
  },
  {
    path: SETTING_AUTOMATED_RULES_PATH,
    allowedPermissions: [PERMISSIONS.ADMIN, PERMISSIONS.STANDARD],
    element: <AutomatedRules />,
  },
  {
    path: `${SETTING_AUTOMATED_RULES_PATH}/:id`,
    allowedPermissions: [PERMISSIONS.ADMIN, PERMISSIONS.STANDARD],
    element: <AutomatedRules />,
  },
  {
    path: SETTING_USER_PATH,
    allowedPermissions: [
      PERMISSIONS.ADMIN,
      PERMISSIONS.STANDARD,
      PERMISSIONS.READ_ONLY,
    ],
    element: <UserSettings />,
  },
  {
    path: SETTING_ACCOUNT_PATH,
    allowedPermissions: [PERMISSIONS.ADMIN],
    element: <UserSettings />,
  },
  {
    path: SETTING_USAGE_BILLING_PATH,
    allowedPermissions: [PERMISSIONS.ADMIN],
    element: <UserSettings />,
  },
  {
    path: '*',
    allowedPermissions: [
      PERMISSIONS.ADMIN,
      PERMISSIONS.STANDARD,
      PERMISSIONS.READ_ONLY,
    ],
    element: <Navigate replace to="/dashboard" />,
  },
  {
    path: '*',
    allowedPermissions: [
      PERMISSIONS.ADMIN,
      PERMISSIONS.STANDARD,
      PERMISSIONS.READ_ONLY,
    ],
    element: <Navigate replace to="/dashboard" />,
  },
];

export const AppRoutes = ({ validAccess }) => {
  return (
    <Routes>
      {ROUTES.map(({ path, allowedPermissions, element, needValidAccess }, index) => (
        <Route
          key={path}
          path={path}
          element={
            allowedPermissions && allowedPermissions.length? (
              <ProtectedRoute
                allowedPermissions={allowedPermissions}
                validAccess={needValidAccess ? validAccess : true}
              >
                {element}
              </ProtectedRoute>
            ) : (
              element
            )
          }
        />
      ))}
    </Routes>
  );
};
