import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  formData: {
    month_start_day: { value: '1', label: '1st' },
    week_start_day: { value: '1', label: 'Monday' },
    start_date: '',
    end_date: '',
    amount: null,
    type: null,
    name: '',
    google_budget: [],
    client: null,
  },
  clients: [],
  isActive: {
    step1: true,
    step2: false,
    step3: false,
  },
  step: 'client',
  tab: 'monthly',
  setCampaignsTab: 'data',
  sources: [],
  choosenSources: [],
  accounts: [],
  choosenAccounts: [],
  campaigns: [],
  tableChoosenData: [],
  choosenCampaigns: [],
  isConfirm: false,
  startDateError: false,
  endDateError: false,
  amountError: '',
  typeError: false,
  nameError: false,
  uniqueNameError: false,
  isUpdateBudget: false,
  updatedBudgetOrCampaignId: '',
  isProcessSyncDataSource: false,
  timeOutIds: [],
  statusSyncOfDataSource: [],
};

const createBudgetSlice = createSlice({
  name: 'createBudget',
  initialState,
  reducers: {
    setUpdatedBudgetOrCampaignId: (state, action) => {
      state.updatedBudgetOrCampaignId = action.payload;
    },
    setIsActive: (state, action) => {
      state.isActive = action.payload;
    },
    addFormData: (state, action) => {
      state.formData = action.payload;
    },
    setClients: (state, action) => {
      state.clients = action.payload;
    },
    setStatusUpdateBudget: (state, action) => {
      state.isUpdateBudget = action.payload;
    },
    addClient: (state, action) => {
      state.formData.client = action.payload;
    },
    changeStep: (state, action) => {
      state.step = action.payload;
    },
    changeTab: (state, action) => {
      state.tab = action.payload;
    },
    changeSetCampaignTab: (state, action) => {
      state.setCampaignsTab = action.payload;
    },
    addSources: (state, action) => {
      state.sources = action.payload;
    },
    addAccounts: (state, action) => {
      state.accounts = action.payload;
    },
    addCampaigns: (state, action) => {
      state.campaigns = action.payload;
    },
    changeChoosenSources: (state, action) => {
      state.choosenSources = action.payload;
    },
    changeChoosenAccounts: (state, action) => {
      state.choosenAccounts = action.payload;
    },
    changeTableChoosenData: (state, action) => {
      state.tableChoosenData = action.payload;
    },
    changeChoosenCampaigns: (state, action) => {
      state.choosenCampaigns = action.payload;
    },
    changeIsConfirm: (state, action) => {
      state.isConfirm = action.payload;
    },
    setStartDateError: (state, action) => {
      state.startDateError = action.payload;
    },
    setEndDateError: (state, action) => {
      state.endDateError = action.payload;
    },
    setAmountError: (state, action) => {
      state.amountError = action.payload;
    },
    setTypeError: (state, action) => {
      state.typeError = action.payload;
    },
    setNameError: (state, action) => {
      state.nameError = action.payload;
    },
    setUniqueNameError: (state, action) => {
      state.uniqueNameError = action.payload;
    },
    resetDates: state => {
      state.formData.month_start_day = initialState.formData.month_start_day;
      state.formData.week_start_day = initialState.formData.week_start_day;
      state.formData.start_date = initialState.formData.start_date;
      state.formData.end_date = initialState.formData.end_date;
    },
    setIsProcessSyncDataSource: (state, action) => {
      state.isProcessSyncDataSource = action.payload;
    },
    setTimeOutIds: (state, action) => {
      state.timeOutIds.push(action.payload);
    },
    resetTimeOutId: (state, action) => {
      state.timeOutIds = [];
    },
    addStatusSyncOfDataSource: (state, action) => {
      const { sourceId, activitySyncHistoryLog, accountId } = action.payload;
      state.statusSyncOfDataSource.push({
        sourceId,
        activitySyncHistoryLog,
        ...(accountId && { accountId }),
      });
    },
    updateStatusSyncOfDataSource: (state, action) => {
      const { sourceId, activitySyncHistoryLog, accountId } = action.payload;
      const index = state.statusSyncOfDataSource.findIndex(
        status =>
          status.sourceId === sourceId &&
          (!accountId || status.accountId === accountId),
      );

      if (index !== -1) {
        state.statusSyncOfDataSource[index] = {
          ...state.statusSyncOfDataSource[index],
          sourceId,
          activitySyncHistoryLog,
          ...(accountId && { accountId }),
        };
      }
    },
    resetState: () => initialState,
  },
});

export const {
  setIsActive,
  addFormData,
  setClients,
  addClient,
  changeStep,
  changeTab,
  changeSetCampaignTab,
  addSources,
  addAccounts,
  addCampaigns,
  changeChoosenSources,
  changeChoosenAccounts,
  changeTableChoosenData,
  changeChoosenCampaigns,
  changeIsConfirm,
  setStartDateError,
  setEndDateError,
  setAmountError,
  setTypeError,
  setNameError,
  setUniqueNameError,
  resetDates,
  resetState,
  setStatusUpdateBudget,
  setUpdatedBudgetOrCampaignId,
  setStatusFirstSyncOfDataSource,
  setIsProcessSyncDataSource,
  setTimeOutIds,
  resetTimeOutId,
  addStatusSyncOfDataSource,
  updateStatusSyncOfDataSource,
} = createBudgetSlice.actions;

export default createBudgetSlice.reducer;
