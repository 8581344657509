import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from '../../components/reusable/Modals/Modal';
import api from '../../config/api';
import apiv2 from '../../config/apiv2';
import { CREATE_BUDGET_PATH, CREATE_CLIENT_PATH, DASHBOARD_PATH } from '../../contants';
import { MESSAGE_ERROR } from '../../contants/messageError';
import {
  resetStateError,
  setCountryError,
  setCurrencyError,
  setNameError,
  setShortNameError,
  setTimezoneError,
  setUniqueNameError,
} from '../../features/createClient/createClientSlice';
import { setMe } from '../../features/me/meSlice';
import {
  error,
  failCreateClient,
  failUpdateClient,
  notyfError,
  successCreateClient,
  successUpdateClient,
} from '../../helpers/notyf';
import css from './CreateClient.module.css';
import { countries, currencySymbols, timezones } from './constants';

const CreateClient = () => {
  const [mode, setMode] = useState('create');
  const [options, setOptions] = useState({});
  const [formData, setFormData] = useState({
    name: '',
    country: '',
    timezone: '',
    currency: '',
  });
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setOptions({
      country: countries,
      timezones: timezones,
      currency: currencySymbols.map(el => ({
        value: el.numeric,
        label: el.name,
      })),
    });

    return () => {
      dispatch(resetStateError());
    };
  }, []);

  useEffect(() => {
    if (params.id && !isNaN(params.id)) {
      setMode('edit');
      !!options.country &&
        api.GET_CLIENT(params.id).then(res => {
          const data = res;

          const country = options.country.find(
            el => el.value === data.country,
          ) ?? { value: '0', label: 'Other' };

          const currency = options.currency.find(
            el => el.value === data.currency,
          ) ?? { value: '0', label: 'Other (None)' };

          const timezone =
            options.timezones?.[country.label]?.find(
              el => el.label == data.timezone,
            ) ?? null;

          setFormData({
            ...data,
            country,
            currency,
            timezone,
          });
        }).catch(err => {
          const { response } = err;
          if(response?.status === 404) {
            notyfError("Client isn't existed");
            navigate(CREATE_CLIENT_PATH, { replace: true });
          }
        });
    } else {
      setMode('create');
      setFormData({
        name: '',
        country: '',
        timezone: '',
        currency: '',
      });
    }
  }, [params.id, options]);

  const handleCancel = () => navigate(DASHBOARD_PATH, { replace: true });

  const handleSubmit = async e => {
    e.preventDefault();

    if (
      !formData.name ||
      formData.name.length < 2 ||
      !formData.country ||
      !formData.timezone ||
      !formData.currency
    ) {
      if (!formData.name) dispatch(setNameError(true));
      if (formData.name && formData.name.length < 2)
        dispatch(setShortNameError(true));
      if (!formData.country) dispatch(setCountryError(true));
      if (!formData.timezone) dispatch(setTimezoneError(true));
      if (!formData.currency) dispatch(setCurrencyError(true));
      return;
    }

    const formatedData = {
      name: formData.name,
      country: formData.country.value,
      timezone: formData.timezone.label,
      currency: formData.currency.value,
    };

    if (mode === 'create') {
      api
        .CHECK_CLIENT_NAME({ clientName: formatedData.name.trim() })
        .then(res => {
          api
            .CREATE_CLIENT(formatedData)
            .then(res =>
              res !== null
                ? successCreateClient() &&
                  navigate(`${CREATE_BUDGET_PATH}/client`, { replace: true })
                : failCreateClient(),
            )
            .then(() => {
              apiv2.GET_ME().then(res => dispatch(setMe(res.data)));
            });
        })
        .catch(err => {
          const { response } = err;
          const message = response.data.name[0];

          if (message === MESSAGE_ERROR.DUPLICATE_NAME_CLIENT && response.status === 400) {
            dispatch(setUniqueNameError(true));
          } else {
            error(err);
          }
        });
    } else {
      api
        .CHECK_CLIENT_NAME({
          clientName: formatedData.name,
          clientId: params.id,
        })
        .then(res => {
          api
            .UPDATE_CLIENT(params.id, formatedData)
            .then(res =>
              res !== null
                ? successUpdateClient() &&
                  navigate(DASHBOARD_PATH, { replace: true })
                : failUpdateClient(),
            );
        })
        .catch(err => {
          const { response } = err;
          const message = response.data.name[0];

          if (message === MESSAGE_ERROR.DUPLICATE_NAME_CLIENT && response.status === 400) {
            dispatch(setUniqueNameError(true));
          } else {
            error(err);
          }
        });
    }
  };

  const handleChange = (e, a) => {
    if (a) {
      if (a.name === 'country') dispatch(setCountryError(false));
      if (a.name === 'timezone') dispatch(setTimezoneError(false));
      if (a.name === 'currency') dispatch(setCurrencyError(false));
      setFormData({
        ...formData,
        [a.name]: e,
        ...(a.name === 'country' ? { timezone: '' } : {}),
      });
    } else {
      if (e.target.name === 'name') {
        dispatch(setNameError(false));
        dispatch(setShortNameError(false));
        dispatch(setUniqueNameError(false));
      }
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  return (
    <div className={css.main}>
      <Modal
        type="client"
        mode={mode}
        data={formData}
        options={options}
        onSubmit={handleSubmit}
        onChange={handleChange}
        onCancel={handleCancel}
      />
    </div>
  );
};

export default CreateClient;
