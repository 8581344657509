import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../../config/api';
import apiv2 from '../../../config/apiv2';
import { MESSAGE_ERROR } from '../../../contants/messageError';
import {
  changeIsAllChecked,
  changeTab,
  changeTab2,
  changeTableData,
  setFilterElems,
} from '../../../features/dashboard/dashboardSlice';
import { toggleRefresh } from '../../../features/root/rootSlice';
import { failUpdateBudget, successUpdateBudget } from '../../../helpers/notyf';
import BudgetPacingTable from './BudgetPacingTable';
import BudgetPerformanceTable from './BudgetPerformanceTable';
import CampaignPerformanceTable from './CampaignPerformanceTable';
import ClientBudgetTable from './ClientBudgetTable';
import ClientPerformanceTable from './ClientPerformanceTable';
import css from './Tables.module.css';
import ClientDashboardTable from './ClientDashboardTable';
import { CLIENT, CLIENT_METRIC } from '../../../contants/datasource';
import {
  budgetSort,
  campaignSort,
  clientSort,
  extractTypeCustomView,
  getMetricsByType,
} from '../../../utils';
import BudgetDashboardTable from './BudgetDashboardTable';

const DashboardTable = ({ type, filterElems, isLoading, setIsLoading }) => {
  const [budgetToEdit, setBudgetToEdit] = useState(null);
  const [isAmountHover, setIsAmountHover] = useState(false);
  const [isAmount, setIsAmount] = useState(false);
  const [isEditHover, setIsEditHover] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(null);
  const [error, setError] = useState('');

  const tableRef = useRef(null);
  const { tableData, isAllChecked, startDate, endDate, tab, tab2 } =
    useSelector(state => state.dashboard);
  const { isRefresh } = useSelector(state => state.root);

  const isDefaultCurrentCycleView = !startDate && !endDate;

  const dispatch = useDispatch();

  const syncBudgetStatus = async (newBudget, intervalId) => {
    await apiv2
      .SYNC_BUDGET_STATUS(newBudget.id)
      .then(res => {
        const { status } = res.data;

        if (status) {
          localStorage.removeItem('newBudget');
          newBudget.syncStatus = true;
          if (intervalId) clearInterval(intervalId);
          handleGetTableData();
        }
      })
      .catch(err => err);
  };

  const handleGetTableData = () => {
    setIsLoading(true);

    if (type === 'clients-budget-pacing') {
      const params = {
        clients_id: filterElems.length
          ? filterElems.map(elem => elem.client_id).join(',')
          : null,
      };
      if (startDate && endDate) {
        params.date_from = moment(startDate).format('YYYY-MM-DD');
        params.date_to = moment(endDate).format('YYYY-MM-DD');
      }
      api
        .GET_CLIENT_BUDGET_PACING(params)
        .then(res => {
          const sortedClientsBudget = res.sort((a, b) => {
            if (a.client_name < b.client_name) {
              return -1;
            }
            if (a.client_name > b.client_name) {
              return 1;
            }
            return 0;
          });

          dispatch(
            changeTableData(
              sortedClientsBudget.map(el => ({ ...el, isChecked: false })),
            ),
          );
          isRefresh && dispatch(toggleRefresh());
          setIsLoading(false);
        })
        .catch(error => {
          setIsLoading(false);
        });
    } else if (type === 'budgets-budget-pacing') {
      const params = {
        clients_id: filterElems.length
          ? [...new Set(filterElems.map(elem => elem.client_id))].join(',')
          : null,
        budgets_id: filterElems.length
          ? filterElems[0]?.budget_id
            ? filterElems.map(elem => elem.budget_id).join(',')
            : null
          : null,
      };
      if (startDate && endDate) {
        params.date_from = moment(startDate).format('YYYY-MM-DD');
        params.date_to = moment(endDate).format('YYYY-MM-DD');
      }
      api
        .GET_BUDGET_BUDGET_PACING(params)
        .then(res => {
          const sortedBudgetsBudget = res.sort((a, b) => {
            if (a.client_name < b.client_name) {
              return -1;
            }
            if (a.client_name > b.client_name) {
              return 1;
            }

            if (a.budget_name < b.budget_name) {
              return -1;
            }
            if (a.budget_name > b.budget_name) {
              return 1;
            }

            return 0;
          });

          dispatch(
            changeTableData(
              sortedBudgetsBudget.map(el => ({ ...el, isChecked: false })),
            ),
          );
          isRefresh && dispatch(toggleRefresh());
          setIsLoading(false);
        })
        .catch(error => {
          setIsLoading(false);
        });
    } else if (type === 'clients-performance') {
      const params = {
        clients_id: filterElems.length
          ? filterElems.map(elem => elem.client_id).join(',')
          : null,
      };
      if (startDate && endDate) {
        params.date_from = moment(startDate).format('YYYY-MM-DD');
        params.date_to = moment(endDate).format('YYYY-MM-DD');
      }
      api
        .GET_CLIENT_PERFORMANCE(params)
        .then(res => {
          const sortedClientsPerformance = res.sort((a, b) => {
            if (a.client_name < b.client_name) {
              return -1;
            }
            if (a.client_name > b.client_name) {
              return 1;
            }
            return 0;
          });

          dispatch(
            changeTableData(
              sortedClientsPerformance.map(el => ({
                ...el,
                isChecked: false,
              })),
            ),
          );
          isRefresh && dispatch(toggleRefresh());
          setIsLoading(false);
        })
        .catch(error => {
          setIsLoading(false);
        });
    } else if (type === 'budgets-performance') {
      const params = {
        clients_id: filterElems.length
          ? filterElems.map(elem => elem.client_id).join(',')
          : null,
      };
      if (startDate && endDate) {
        params.date_from = moment(startDate).format('YYYY-MM-DD');
        params.date_to = moment(endDate).format('YYYY-MM-DD');
      }
      api
        .GET_BUDGET_PERFORMANCE(params)
        .then(res => {
          const sortedBugdetsPerformance = res.sort((a, b) => {
            if (a.client_name < b.client_name) {
              return -1;
            }
            if (a.client_name > b.client_name) {
              return 1;
            }

            if (a.budget_name < b.budget_name) {
              return -1;
            }
            if (a.budget_name > b.budget_name) {
              return 1;
            }

            return 0;
          });

          dispatch(
            changeTableData(
              sortedBugdetsPerformance.map(el => ({ ...el, isChecked: false })),
            ),
          );
          isRefresh && dispatch(toggleRefresh());
          setIsLoading(false);
        })
        .catch(error => {
          setIsLoading(false);
        });
    } else if (type === 'campaigns-performance') {
      const params = {
        campaigns_id: filterElems.length
          ? filterElems[0]?.campaign_id
            ? filterElems.map(elem => elem.campaign_id).join(',')
            : null
          : null,
        budgets_id: filterElems.length
          ? filterElems[0]?.budget_id
            ? filterElems.map(elem => elem.budget_id).join(',')
            : null
          : null,
        clients_id: filterElems.length
          ? filterElems[0]?.client_id
            ? [...new Set(filterElems.map(elem => elem.client_id))].join(',')
            : null
          : null,
      };

      if (startDate && endDate) {
        params.date_from = moment(startDate).format('YYYY-MM-DD');
        params.date_to = moment(endDate).format('YYYY-MM-DD');
      }
      api
        .GET_CAMPAIGNS_PERFORMANCE(params)
        .then(res => {
          const sortedCampaignsPerformance = res.sort((a, b) => {
            if (a.client_name < b.client_name) {
              return -1;
            }
            if (a.client_name > b.client_name) {
              return 1;
            }

            if (a.budget_name < b.budget_name) {
              return -1;
            }
            if (a.budget_name > b.budget_name) {
              return 1;
            }

            if (a.campaign_name < b.campaign_name) {
              return -1;
            }
            if (a.campaign_name > b.campaign_name) {
              return 1;
            }

            return 0;
          });

          dispatch(
            changeTableData(
              sortedCampaignsPerformance.map(el => ({
                ...el,
                isChecked: false,
              })),
            ),
          );
          isRefresh && dispatch(toggleRefresh());
          setIsLoading(false);
        })
        .catch(error => {
          setIsLoading(false);
        });
    } else {
      fetchAndProcessCustomView(type);
    }
  };

  const fetchAndProcessCustomView = async type => {
    try {
      const { prefix, customViewRaw } = extractTypeCustomView(type);
      const { customView, result } = getMetricsByType(customViewRaw, CLIENT);
      const clients = result.map(client => ({
        client_id: client.split('/')[1],
        client_name: client.split('/')[2],
      }));

      let dataSorted = [];
      if (prefix === 'clients') {
        const params = {
          clients_id: filterElems.length
            ? filterElems.map(elem => elem.client_id).join(',')
            : clients.length
            ? clients.map(elem => elem.client_id).join(',')
            : null,
        };

        if (startDate && endDate) {
          params.date_from = moment(startDate).format('YYYY-MM-DD');
          params.date_to = moment(endDate).format('YYYY-MM-DD');
        }

        const data = await api.GET_METRICS_OF_CLIENT_CUSTOM_VIEW(
          customView.id,
          params,
        );
        dataSorted = data.sort(clientSort);
      } else if (prefix === 'budgets') {
        const params = {
          clients_id: filterElems.length
            ? filterElems.map(elem => elem.client_id).join(',')
            : clients.length
            ? clients.map(elem => elem.client_id).join(',')
            : null,
          budgets_id: filterElems.length
            ? filterElems[0]?.budget_id
              ? filterElems.map(elem => elem.budget_id).join(',')
              : null
            : null,
        };
        if (startDate && endDate) {
          params.date_from = moment(startDate).format('YYYY-MM-DD');
          params.date_to = moment(endDate).format('YYYY-MM-DD');
        }

        const data = await api.GET_METRICS_OF_BUDGET_CUSTOM_VIEW(
          customView.id,
          params,
        );
        dataSorted = data.sort(budgetSort);
      } else if (prefix === 'campaigns') {
        const params = {
          clients_id: filterElems.length
            ? filterElems.map(elem => elem.client_id).join(',')
            : clients.length
            ? clients.map(elem => elem.client_id).join(',')
            : null,
          budgets_id: filterElems.length
            ? filterElems[0]?.budget_id
              ? filterElems.map(elem => elem.budget_id).join(',')
              : null
            : null,
          campaigns_id: filterElems.length
            ? filterElems[0]?.campaign_id
              ? filterElems.map(elem => elem.campaign_id).join(',')
              : null
            : null,
        };

        const data = await api.GET_CAMPAIGNS_PERFORMANCE(params);
        dataSorted = data.sort(campaignSort);
      }

      dispatch(
        changeTableData(dataSorted.map(el => ({ ...el, isChecked: false }))),
      );
      isRefresh && dispatch(toggleRefresh());
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const rawNewBudget = localStorage.getItem('newBudget');
    const newBudget = JSON.parse(rawNewBudget);
    let intervalId;

    // check sync_budget_status first time
    (async () => {
      if (newBudget && newBudget.id) {
        await apiv2
          .SYNC_BUDGET_STATUS(newBudget.id)
          .then(res => {
            const { status } = res.data;

            if (status) {
              localStorage.removeItem('newBudget');
              newBudget.syncStatus = true;
            } else {
              intervalId = setInterval(async () => {
                await syncBudgetStatus(newBudget, intervalId);
              }, 3000);
            }
          })
          .catch(err => {
            const { response } = err;
            if (response.status === 404) {
              localStorage.removeItem('newBudget');
            }
          });
      }
    })();

    handleGetTableData();

    return () => {
      clearInterval(intervalId);
    };
  }, [type, startDate, endDate, filterElems]);

  useEffect(() => {
    if (isRefresh) {
      handleGetTableData();
    }
  }, [isRefresh]);

  const handleCheckAll = () => {
    if (!isAllChecked) {
      dispatch(changeIsAllChecked(true));
      return dispatch(
        changeTableData(tableData.map(el => ({ ...el, isChecked: true }))),
      );
    } else {
      dispatch(changeIsAllChecked(false));
      return dispatch(
        changeTableData(tableData.map(el => ({ ...el, isChecked: false }))),
      );
    }
  };

  /**
   * Handles the checkbox change event for different types of data (clients, budgets, campaigns).
   * Updates the table data by toggling the 'isChecked' property of the selected item.
   * Dispatches actions to update the state accordingly.
   *
   * @param {any} objSelected - The selected object, which contains the id of the item to be checked/unchecked (string in clients
   * ,campaigns and obj in campaigns).
   * @param {Event} e - The event object from the checkbox change event.
   */
  const handleCheckOne = (objSelected, e) => {
    let newTableData = [...tableData];

    if (type === 'clients-budget-pacing') {
      newTableData = newTableData.map(el => {
        if (+el.client_id === +objSelected) {
          return { ...el, isChecked: !el.isChecked };
        }

        return el;
      });

      dispatch(changeTableData(newTableData));
    }

    if (type === 'budgets-budget-pacing') {
      newTableData = newTableData.map(el => {
        if (+el.budget_id === +objSelected) {
          return { ...el, isChecked: !el.isChecked };
        }

        return el;
      });
      dispatch(changeTableData(newTableData));
    }

    if (type === 'campaigns-performance') {
      newTableData = newTableData.map(el => {
        if (
          el.campaign_id === objSelected?.campaign_id &&
          el.budget_id === objSelected?.budget_id
        ) {
          return { ...el, isChecked: !el.isChecked };
        }

        return el;
      });
      dispatch(changeTableData(newTableData));
    }

    if (tableData.length === newTableData.filter(el => el.isChecked).length) {
      dispatch(changeIsAllChecked(true));
    } else dispatch(changeIsAllChecked(false));
  };

  const handleOnMouseEnter = (id, e) => setIsEditHover(id);

  const handleOnMouseLeave = () => {
    setIsEditHover(null);
  };

  const handleOnAmountMouseEnter = (id, e) => {
    setIsAmountHover(true);
    setIsEditHover(id);
  };

  const handleOnAmountMouseLeave = () => {
    setIsAmountHover(false);
    setIsEditHover(null);
  };

  const handleGetBudget = id => {
    api.GET_BUDGET(id).then(res => {
      setBudgetToEdit({
        id: res.id,
        name: res.name,
        amount: res.amount,
        client: res.client.id,
        campaigns_id: res.campaigns.map(elem => elem.id),
        renew_type: res.renew_type,
      });
      setIsEdit(true);
    });
  };

  const handleNameEditClick = (id, e) => {
    setError('');
    setIsEditOpen(id);
    handleGetBudget(id);
    setIsAmount(false);
  };

  const handleAmountEditClick = (id, e) => {
    setError('');
    setIsEditOpen(id);
    handleGetBudget(id);
    setIsAmount(true);
  };

  const handleCloseEdit = () => {
    setIsEdit(false);
    setIsEditOpen(null);
    setBudgetToEdit(null);
  };

  const handleChange = e => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === 'amount') {
      if (value === '') {
        value = '0';
      } else if (+value > 1000000000 || isNaN(value)) return;
    }

    setError('');
    setBudgetToEdit({ ...budgetToEdit, [e.target.name]: value });
  };

  const handleFilterByItem = el => {
    if (el.row_type === 'total') return;

    dispatch(setFilterElems([el]));

    if (tab === 'clients' && tab2 === 'budget-pacing') {
      dispatch(changeTab('budgets'));
    } else if (tab === 'budgets' && tab2 === 'budget-pacing') {
      dispatch(changeTab('campaigns'));
      dispatch(changeTab2('performance'));
    } else if (tab2 === 'performance' && tab === 'clients') {
      dispatch(changeTab('budgets'));
    } else if (tab2 === 'performance' && tab === 'budgets') {
      dispatch(changeTab('campaigns'));
    } else {
      handleFilterForCustomView(tab);
    }
  };

  const handleFilterForCustomView = tab => {
    const { prefix, _ } = extractTypeCustomView(tab);

    if (prefix === 'clients') {
      dispatch(changeTab('budgets'));
    } else if (prefix === 'budgets') {
      dispatch(changeTab('campaigns'));
    }
  };

  const handleGoClient = el => {
    if (el.row_type === 'total') return;

    dispatch(setFilterElems([el]));
    dispatch(changeTab('clients'));
  };

  const handleGoBudget = el => {
    dispatch(setFilterElems([el]));
    dispatch(changeTab('budgets'));
  };

  const handleEdit = () => {
    if (!isAmount) {
      if (budgetToEdit.name?.trim() === '') {
        setError('Please enter a budget name');
      }

      api
        .CHECK_BUDGET_NAME({
          budgetName: budgetToEdit.name,
          budgetId: budgetToEdit.id,
        })
        .then(res => {
          api.UPDATE_BUDGET(budgetToEdit, budgetToEdit.id).then(res => {
            if (res.data) {
              successUpdateBudget();
              handleCloseEdit();
              handleGetTableData();
            } else {
              failUpdateBudget();
            }
          });
        })
        .catch(err => {
          const { response } = err;
          const message = response.data.name[0];

          if (
            message === MESSAGE_ERROR.DUPLICATE_NAME_BUDGET &&
            response.status === 400
          ) {
            setError('A budget with this name already exists');
          } else {
            console.log(err);
            // error(err);
          }
        });
    } else {
      api
        .UPDATE_BUDGET(
          { ...budgetToEdit, amount: Math.round(+budgetToEdit.amount) },
          budgetToEdit.id,
        )
        .then(res => {
          if (res.data) {
            successUpdateBudget();
            handleCloseEdit();
            handleGetTableData();
          } else {
            failUpdateBudget();
          }
        })
        .catch(res => failUpdateBudget());
    }
  };

  // const renderTableHead = () => {
  //   let headers = [];

  //   if (type === 'budgets-budget-pacing') {
  //     headers = [
  //       {
  //         key: '',
  //         label: (
  //           <input
  //             className={css.checkbox}
  //             type="checkbox"
  //             checked={isAllChecked}
  //             onChange={handleCheckAll}
  //           />
  //         ),
  //         className: css.thA,
  //       },
  //       {
  //         key: 'budget_name',
  //         label: 'Budget',
  //         type: 'string'
  //       },
  //       {
  //         key: 'client_name',
  //         label: 'Client',
  //         type: 'string'
  //       },
  //       {
  //         key: 'budget_amount',
  //         label: 'Budget Amount',
  //         type: 'number'
  //       },
  //       {
  //         key: 'spend',
  //         label: 'Spend',
  //         type: 'number'
  //       },
  //       {
  //         key: 'trend',
  //         label: 'Trend',
  //         type: 'number'
  //       },
  //       {
  //         key: 'remaining_budget',
  //         label: 'Remaining Budget',
  //         type: 'number'
  //       },
  //       {
  //         key: 'ideal_daily_spend',
  //         label: 'Ideal Daily Spend',
  //         type: 'number'
  //       },
  //       {
  //         key: 'yesterday_spend',
  //         label: "Yesterday's Spend",
  //         type: 'number'
  //       },
  //       {
  //         key: 'tree_days_avg_spend',
  //         label: '3 Day Avg. Spend',
  //         type: 'number'
  //       },
  //       {
  //         key: 'forecast_spend',
  //         label: 'Forecast Spend',
  //         type: 'number'
  //       },
  //       {
  //         key: 'lost_is_budget_percentage',
  //         label: 'Lost IS (Budget)',
  //         type: 'number'
  //       },
  //     ];
  //   }

  //   if (type === 'budgets-performance') {
  //     headers = [
  //       {
  //         key: 'budget_name',
  //         label: 'Budget',
  //         type: 'string',
  //         className: css.thNoWidth
  //       },
  //       {
  //         key: 'client_name',
  //         label: 'Client',
  //         type: 'string',
  //         className: css.thNoWidth
  //       },
  //       {
  //         key: 'spend',
  //         label: 'Spend',
  //         type: 'number',
  //         className: css.thNoWidth
  //       },
  //       {
  //         key: 'conversions',
  //         label: 'Conversions',
  //         type: 'number',
  //         className: css.thNoWidth
  //       },
  //       {
  //         key: 'cpa',
  //         label: 'CPA',
  //         type: 'number',
  //         className: css.thNoWidth
  //       },
  //       {
  //         key: 'revenue',
  //         label: 'Revenue',
  //         type: 'number',
  //         className: css.thNoWidth
  //       },
  //       {
  //         key: 'roas',
  //         label: 'ROAS',
  //         type: 'number',
  //         className: css.thNoWidth
  //       },
  //       {
  //         key: 'seven_days_cpa',
  //         label: '7 Day CPA',
  //         type: 'number',
  //         className: css.thNoWidth
  //       },
  //       {
  //         key: 'seven_days_roas',
  //         label: '7 Day ROAS',
  //         type: 'number',
  //         className: css.thNoWidth
  //       },
  //       {
  //         key: 'seven_days_cpa_change',
  //         label: '7 Day CPA change',
  //         type: 'number',
  //         className: css.thNoWidth
  //       },
  //       {
  //         key: 'seven_days_roas_change',
  //         label: '7 Day ROAS change',
  //         type: 'number',
  //         className: css.thNoWidth
  //       },
  //       {
  //         key: 'forecast_conversions',
  //         label: 'Forecast Conv.',
  //         type: 'number',
  //         className: css.thNoWidth
  //       },
  //       {
  //         key: 'forecast_revenue',
  //         label: 'Forecast Revenue',
  //         type: 'number',
  //         className: css.thNoWidth
  //       },
  //     ];
  //   }

  //   if (type === 'clients-performance') {
  //     headers = [
  //       {
  //         key: 'client_name',
  //         label: 'Client',
  //         type: 'string',
  //         className: css.thNoWidth
  //       },
  //       {
  //         key: 'spend',
  //         label: 'Spend',
  //         type: 'number',
  //         className: css.thNoWidth
  //       },
  //       {
  //         key: 'conversions',
  //         label: 'Conversions',
  //         type: 'number',
  //         className: css.thNoWidth
  //       },
  //       {
  //         key: 'cpa',
  //         label: 'CPA',
  //         type: 'number',
  //         className: css.thNoWidth
  //       },
  //       {
  //         key: 'revenue',
  //         label: 'Revenue',
  //         type: 'number',
  //         className: css.thNoWidth
  //       },
  //       {
  //         key: 'roas',
  //         label: 'ROAS',
  //         type: 'number',
  //         className: css.thNoWidth
  //       },
  //       {
  //         key: 'seven_days_cpa',
  //         label: '7 Day CPA',
  //         type: 'number',
  //         className: css.thNoWidth
  //       },
  //       {
  //         key: 'seven_days_roas',
  //         label: '7 Day ROAS',
  //         type: 'number',
  //         className: css.thNoWidth
  //       },
  //       {
  //         key: 'seven_days_cpa_change',
  //         label: '7 Day CPA change',
  //         type: 'number',
  //         className: css.thNoWidth
  //       },
  //       {
  //         key: 'seven_days_roas_change',
  //         label: '7 Day ROAS change',
  //         type: 'number',
  //         className: css.thNoWidth
  //       },
  //       {
  //         key: 'forecast_conversions',
  //         label: 'Forecast Conv.',
  //         type: 'number',
  //         className: css.thNoWidth
  //       },
  //       {
  //         key: 'forecast_revenue',
  //         label: 'Forecast Revenue',
  //         type: 'number',
  //         className: css.thNoWidth
  //       },
  //     ];
  //   }

  //   if (type === 'campaigns-performance') {
  //     headers = [
  //       {
  //         key: '',
  //         label: (
  //           <input
  //             className={css.checkbox}
  //             type="checkbox"
  //             checked={isAllChecked}
  //             onChange={handleCheckAll}
  //           />
  //         ),
  //         className: css.thInput,
  //         style: { width: 30 },
  //       },
  //       {
  //         key: 'source',
  //         label: 'Source',
  //         type: 'string'
  //       },
  //       {
  //         key: 'campaign_name',
  //         label: 'Campaign',
  //         type: 'string'
  //       },
  //       {
  //         key: 'client_name',
  //         label: 'Client',
  //         type: 'string'
  //       },
  //       {
  //         key: 'budget_name',
  //         label: 'Budget',
  //         type: 'string'
  //       },
  //       {
  //         key: 'impressions',
  //         label: 'Impressions',
  //         type: 'number'
  //       },
  //       {
  //         key: 'clicks',
  //         label: 'Clicks',
  //         type: 'number'
  //       },
  //       {
  //         key: 'spend',
  //         label: 'Spend',
  //         type: 'number'
  //       },
  //       {
  //         key: 'conversions',
  //         label: 'Conversions',
  //         type: 'number'
  //       },
  //       {
  //         key: 'cpa',
  //         label: 'CPA',
  //         type: 'number'
  //       },
  //       {
  //         key: 'revenue',
  //         label: 'Revenue',
  //         type: 'number'
  //       },
  //       {
  //         key: 'roas',
  //         label: 'ROAS',
  //         type: 'number'
  //       },
  //       {
  //         key: 'seven_days_cpa_change',
  //         label: '7 Day CPA Change',
  //         type: 'number'
  //       },
  //       {
  //         key: 'seven_days_roas_change',
  //         label: '7 Day ROAS Change',
  //         type: 'number'
  //       },
  //       {
  //         key: 'all_conversions',
  //         label: 'All Conversions',
  //         type: 'number'
  //       },
  //     ];
  //   }

  //   if (type === 'clients-budget-pacing') {
  //     headers = [
  //       {
  //         key: '',
  //         label: (
  //           <input
  //             className={css.checkbox}
  //             type="checkbox"
  //             checked={isAllChecked}
  //             onChange={handleCheckAll}
  //           />
  //         ),
  //         className: css.thA,
  //         style: {display: "block", width: 30}
  //       },
  //       {
  //         key: 'client_name',
  //         label: 'Client',
  //         type: 'string',
  //       },
  //       {
  //         key: 'budget_amount',
  //         label: 'Budget Amount',
  //         type: 'number'
  //       },
  //       {
  //         key: 'spend',
  //         label: 'Spend',
  //         type: 'number'
  //       },
  //       {
  //         key: 'trend',
  //         label: 'Trend',
  //         type: 'number'
  //       },
  //       {
  //         key: 'remaining_budget',
  //         label: 'Remaining Budget',
  //         type: 'number'
  //       },
  //       {
  //         key: 'ideal_daily_spend',
  //         label: 'Ideal Daily Spend',
  //         type: 'number'
  //       },
  //       {
  //         key: 'yesterday_spend',
  //         label: "Yesterday's Spend",
  //         type: 'number'
  //       },
  //       {
  //         key: 'tree_days_avg_spend',
  //         label: '3 Day Avg. Spend',
  //         type: 'number'
  //       },
  //       {
  //         key: 'forecast_spend',
  //         label: 'Forecast Spend',
  //         type: 'number'
  //       },
  //       {
  //         key: 'lost_is_budget_percentage',
  //         label: 'Lost IS (Budget)',
  //         type: 'number'
  //       },
  //     ];
  //   }

  //   return (
  //     <TableHead
  //       headers={headers}
  //       onChangeSort={handleChangeSort}
  //       sortKey={sortKey}
  //       sortOrder={sortOrder}
  //     />
  //   );
  // };

  // const renderTableRows = el => {
  //   const isTotalRow = el?.row_type === 'total';

  //   if (type === 'budgets-budget-pacing') {
  //     return (
  //       <BudgetPacing
  //         type={type}
  //         el={el}
  //         error={error}
  //         budgetToEdit={budgetToEdit}

  //         isTotalRow={isTotalRow}
  //         isDefaultCurrentCycleView={isDefaultCurrentCycleView}
  //         isEditHover={isEditHover}
  //         isAmountHover={isAmountHover}
  //         isEdit={isEdit}
  //         isAmount={isAmount}
  //         isEditOpen={isEditOpen}

  //         handleCheckOne={handleCheckOne}
  //         handleOnMouseEnter={handleOnMouseEnter}
  //         handleOnMouseLeave={handleOnMouseLeave}
  //         handleNameEditClick={handleNameEditClick}
  //         handleChange={handleChange}
  //         handleCloseEdit={handleCloseEdit}
  //         handleEdit={handleEdit}
  //         handleOnAmountMouseEnter={handleOnAmountMouseEnter}
  //         handleOnAmountMouseLeave={handleOnAmountMouseLeave}
  //         handleAmountEditClick={handleAmountEditClick}
  //         handleClickBudget={() => handleFilterByItem(el)}
  //         handleClickClient={() => handleGoClient(el)}
  //       />
  //     );
  //   }

  //   if (type === 'budgets-performance') {
  //     return (
  //       <BudgetsPerformance
  //         type={type}
  //         el={el}
  //         error={error}
  //         budgetToEdit={budgetToEdit}

  //         isTotalRow={isTotalRow}
  //         isDefaultCurrentCycleView={isDefaultCurrentCycleView}
  //         isEditHover={isEditHover}
  //         isEdit={isEdit}
  //         isEditOpen={isEditOpen}

  //         handleOnMouseEnter={handleOnMouseEnter}
  //         handleOnMouseLeave={handleOnMouseLeave}
  //         handleNameEditClick={handleNameEditClick}
  //         handleChange={handleChange}
  //         handleCloseEdit={handleCloseEdit}
  //         handleEdit={handleEdit}
  //         handleClickBudget={() => handleFilterByItem(el)}
  //         handleClickClient={() => handleGoClient(el)}
  //       />
  //     );
  //   }

  //   if (type === 'clients-performance') {
  //     return (
  //       <ClientsPerformance
  //         type={type}
  //         el={el}
  //         isTotalRow={isTotalRow}
  //         isDefaultCurrentCycleView={isDefaultCurrentCycleView}
  //         handleClickClient={() => handleFilterByItem(el)}
  //       />
  //     );
  //   }

  //   if (type === 'campaigns-performance') {
  //     return (
  //       <CampaignsPerformance
  //         type={type}
  //         el={el}
  //         isTotalRow={isTotalRow}
  //         handleClickBudget={() => handleGoBudget(el)}
  //         handleClickClient={() => handleGoClient(el)}
  //         handleCheckOne={handleCheckOne}
  //       />
  //     );
  //   }

  //   if (type === 'clients-budget-pacing') {
  //     return (
  //       <ClientsBudget
  //         type={type}
  //         el={el}
  //         isTotalRow={isTotalRow}
  //         isDefaultCurrentCycleView={isDefaultCurrentCycleView}
  //         handleCheckOne={handleCheckOne}
  //         handleClickClient={() => handleFilterByItem(el)}
  //       />
  //     );
  //   }
  // };

  const renderTable = () => {
    if (type === 'budgets-budget-pacing') {
      return (
        <BudgetPacingTable
          data={tableData}
          type={type}
          error={error}
          budgetToEdit={budgetToEdit}
          budgetIdSelected={isEditOpen}
          isLoading={isLoading}
          isEditHover={isEditHover}
          isAmountHover={isAmountHover}
          isEdit={isEdit}
          isAmount={isAmount}
          isDefaultCurrentCycleView={isDefaultCurrentCycleView}
          handleCheckAll={handleCheckAll}
          handleCheckOne={handleCheckOne}
          handleClickBudget={handleFilterByItem}
          handleClickClient={handleGoClient}
          handleClickSaveBtn={handleEdit}
          handleCloseBudgetNameOrAmountModal={handleCloseEdit}
          handleOpenBudgetAmountModal={handleAmountEditClick}
          handleOpenBudgetNameModal={handleNameEditClick}
          handleChangeBudgetNameOrAmount={handleChange}
          handleOnAmountMouseEnter={handleOnAmountMouseEnter}
          handleOnAmountMouseLeave={handleOnAmountMouseLeave}
          handleOnMouseLeave={handleOnMouseLeave}
          handleOnMouseEnter={handleOnMouseEnter}
        />
      );
    }

    if (type === 'budgets-performance') {
      return (
        <BudgetPerformanceTable
          data={tableData}
          type={type}
          error={error}
          budgetToEdit={budgetToEdit}
          budgetIdSelected={isEditOpen}
          isLoading={isLoading}
          isDefaultCurrentCycleView={isDefaultCurrentCycleView}
          isEditHover={isEditHover}
          isEdit={isEdit}
          handleOnMouseEnter={handleOnMouseEnter}
          handleOnMouseLeave={handleOnMouseLeave}
          handleClickBudget={handleFilterByItem}
          handleClickClient={handleGoClient}
          handleOpenBudgetNameModal={handleNameEditClick}
          handleChangeBudgetName={handleChange}
          handleCloseBudgetNameOrAmountModal={handleCloseEdit}
          handleClickSaveBtn={handleEdit}
        />
      );
    }

    if (type === 'clients-performance') {
      return (
        <ClientPerformanceTable
          data={tableData}
          type={type}
          isLoading={isLoading}
          isDefaultCurrentCycleView={isDefaultCurrentCycleView}
          handleClickClient={handleFilterByItem}
        />
      );
    }

    if (
      type === 'campaigns-performance' ||
      type.split('-')[0] === 'campaigns'
    ) {
      const isCustomView = type.split('-')[1] !== 'performance';
      let metrics = [];
      if (isCustomView) {
        const { customViewRaw } = extractTypeCustomView(type);
        const { result } = getMetricsByType(customViewRaw, CLIENT_METRIC);
        metrics = isCustomView
          ? [
              'source',
              'campaign_id',
              'campaign_name',
              'status',
              'budget_name',
              ...result.map(metric => metric.split('/').pop()),
            ]
          : [];
      }

      return (
        <CampaignPerformanceTable
          data={tableData}
          type={type}
          metrics={metrics}
          isLoading={isLoading}
          isAllChecked={isAllChecked}
          handleCheckAll={handleCheckAll}
          handleCheckOne={handleCheckOne}
          handleClickClient={handleGoClient}
          handleClickBudget={handleGoBudget}
        />
      );
    }

    if (type === 'clients-budget-pacing') {
      return (
        <ClientBudgetTable
          data={tableData}
          type={type}
          isLoading={isLoading}
          isDefaultCurrentCycleView={isDefaultCurrentCycleView}
          isAllChecked={isAllChecked}
          handleClickClient={handleFilterByItem}
          handleCheckOne={handleCheckOne}
          handleCheckAll={handleCheckAll}
        />
      );
    }

    // custom view
    return renderCustomViewTable();
  };

  const renderCustomViewTable = () => {
    const { prefix, customViewRaw } = extractTypeCustomView(type);
    const { result } = getMetricsByType(customViewRaw, CLIENT_METRIC);
    let metrics = result.map(metric => metric.split('/').pop());

    if (prefix === 'clients')
      return (
        <ClientDashboardTable
          data={tableData}
          metrics={metrics}
          type={type}
          isLoading={isLoading}
          isDefaultCurrentCycleView={isDefaultCurrentCycleView}
          isAllChecked={isAllChecked}
          handleClickClient={handleFilterByItem}
          handleCheckOne={handleCheckOne}
          handleCheckAll={handleCheckAll}
        />
      );
    else if (prefix === 'budgets') {
      metrics = ['budget_name', 'budget_id', ...metrics];
      return (
        <BudgetDashboardTable
          data={tableData}
          type={type}
          error={error}
          metrics={metrics}
          budgetToEdit={budgetToEdit}
          budgetIdSelected={isEditOpen}
          isLoading={isLoading}
          isEditHover={isEditHover}
          isAmountHover={isAmountHover}
          isEdit={isEdit}
          isAmount={isAmount}
          isDefaultCurrentCycleView={isDefaultCurrentCycleView}
          handleCheckAll={handleCheckAll}
          handleCheckOne={handleCheckOne}
          handleClickBudget={handleFilterByItem}
          handleClickClient={handleGoClient}
          handleClickSaveBtn={handleEdit}
          handleCloseBudgetNameOrAmountModal={handleCloseEdit}
          handleOnAmountMouseEnter={handleOnAmountMouseEnter}
          handleOnAmountMouseLeave={handleOnAmountMouseLeave}
          handleOnMouseLeave={handleOnMouseLeave}
          handleOnMouseEnter={handleOnMouseEnter}
          handleChangeBudgetNameOrAmount={handleChange}
          handleOpenBudgetAmountModal={handleAmountEditClick}
          handleOpenBudgetNameModal={handleNameEditClick}
        />
      );
    }
  };

  return (
    <>
      <div className={css.dashboardBody} id="dashboardTableBody" ref={tableRef}>
        {/* <table className={css.table}>
          <thead
            className={`${css.dashboardThead} ${
              isLoading ? css.loadingBar : ''
            }`}
          >
            {renderTableHead()}
          </thead>

          {sortedData.length > 0 &&
            sortedData.map(el => <>{renderTableRows(el)}</>)}
        </table> */}
        {renderTable()}
      </div>
      {/* <div style={{ paddingTop: '20px' }}></div> */}
      {/* <div className={css.dashboardBody} id="dashboardTableBody" ref={tableRef}> */}
      {/* <BudgetPerformanceTable
          data={tableData}
          type={type}
          error={error}
          budgetToEdit={budgetToEdit}
          budgetIdSelected={isEditOpen}

          isLoading={isLoading}
          isDefaultCurrentCycleView={isDefaultCurrentCycleView}
          isEditHover={isEditHover}
          isEdit={isEdit}

          handleOnMouseEnter={handleOnMouseEnter}
          handleOnMouseLeave={handleOnMouseLeave}
          handleNameEditClick={handleNameEditClick}
          handleChange={handleChange}
          handleCloseEdit={handleCloseEdit}
          handleEdit={handleEdit}
          handleClickBudget={handleFilterByItem}
          handleClickClient={handleGoClient}
        /> */}

      {/* <BudgetPacingTable
          data={tableData}
          type={type}
          error={error}
          budgetToEdit={budgetToEdit}
          budgetIdSelected={isEditOpen}

          isLoading={isLoading}
          isEditHover={isEditHover}
          isAmountHover={isAmountHover}
          isEdit={isEdit}
          isAmount={isAmount}
          isDefaultCurrentCycleView={isDefaultCurrentCycleView}

          handleCheckAll={handleCheckAll}
          handleCheckOne={handleCheckOne}
          handleClickBudget={handleFilterByItem}
          handleClickClient={handleGoClient}
          handleChange={handleChange}
          handleCloseEdit={handleCloseEdit}
          handleEdit={handleEdit}
          handleNameEditClick={handleNameEditClick}
          handleOnAmountMouseEnter={handleOnAmountMouseEnter}
          handleOnAmountMouseLeave={handleOnAmountMouseLeave}
          handleOnMouseLeave={handleOnMouseLeave}
          handleOnMouseEnter={handleOnMouseEnter}
          handleAmountEditClick={handleAmountEditClick}
        /> */}
      {/* <CampaignPerformanceTable
          data={tableData}
          type={type}
          isLoading={isLoading}
          isAllChecked={isAllChecked}
          handleCheckAll={handleCheckAll}
          handleCheckOne={handleCheckOne}
          handleClickClient={handleGoClient}
          handleClickBudget={handleGoBudget}
        /> */}
      {/* <ClientBudgetTable
          data={tableData}
          type={type}
          isLoading={isLoading}
          isDefaultCurrentCycleView={isDefaultCurrentCycleView}
          isAllChecked={isAllChecked}
          handleClickClient={handleFilterByItem}
          handleCheckOne={handleCheckOne}
          handleCheckAll={handleCheckAll}
        />
        <ClientPerformanceTable
          data={tableData}
          type={type}
          isLoading={isLoading}
          isDefaultCurrentCycleView={isDefaultCurrentCycleView}
          handleClickClient={handleFilterByItem}
        /> */}
      {/* </div> */}
    </>
  );
};

export default DashboardTable;
