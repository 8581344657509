import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import logo from '../../assets/logo.png';
import Modal from '../../components/reusable/Modals/Modal';
import api from '../../config/api';
import { DASHBOARD_PATH } from '../../contants';

const ResetPassword = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    cpassword: '',
    success: false,
    activated: false,
    restorePassword: false,
    message: '',
    remember: false,
  });

  const { reset_token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (reset_token) {
      setFormData({ ...formData, restorePassword: true });
    }
  }, []);

  const handleSubmit = e => {
    e.preventDefault();

    return new Promise((resolve, reject) => {
      if (formData.restorePassword) {
        api
          .RESET_PASSWORD_CONFIRM({
            token: reset_token,
            new_password: formData.password,
            new_password_confirm: formData.cpassword,
          })
          .then(response => {
            if (response?.message == 'password reset') {
              navigate(DASHBOARD_PATH, { replace: true });
            } else {
              formData.success = false;
              formData.message =
                'Something went wrong, please try again after sometime.';
              if (response?.new_password?.password.length > 0) {
                formData.message = response.new_password.password.join('<br>');
              }
              if (response?.non_field_errors.length > 0) {
                formData.message = response.non_field_errors.join('<br>');
              }
            }
            setFormData(formData);
          })
          .catch(e => {
            reject();
          })
          .finally(() => resolve());
      } else {
        api
          .RESET_PASSWORD(formData)
          .then(response => {})
          .catch(e => {
            reject();
          })
          .finally(() => resolve());
      }
    });
  };

  const handleChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  return (
    <main>
      <div className="auth-form-wrapper">
        <img className="logo" alt="Logo" src={logo} />
        <Modal
          type="resetPassword"
          data={formData}
          onSubmit={handleSubmit}
          onChange={handleChange}
        />
      </div>
    </main>
  );
};

export default ResetPassword;
